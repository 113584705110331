import Service from "../Service";
import Vue from "vue";
const resource = "/LotProductionCalibrated/";

export default {

    GetPalletSend(obj, requestID) {
        return Service.post(resource + "GetPalletSend", obj, {
            params: { requestID: requestID },
        });
    },

    GetPalletSendM(obj, requestID) {
        return Service.post(resource + "GetPalletSendM", obj, {
            params: { requestID: requestID },
        });
    },

    GetPallet(obj, requestID) {
        return Service.post(resource + "GetPallet", obj, {
            params: { requestID: requestID },
        });
    },

    GetPalletDed(PrsID, TypeCrop, requestID) {
        return Service.post(resource + "GetPalletDed", "", {
            params: { PrsID: PrsID, TypeCrop: TypeCrop, requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    send(obj, requestID) {
        return Service.post(resource + "send", obj, {
            params: { requestID: requestID },
        });
    },

    transfer(obj, requestID) {
        return Service.post(resource + "transfer", obj, {
            params: { requestID: requestID },
        });
    },


    GetKgCalibrated(obj, requestID) {
        return Service.post(resource + "GetKgCalibrated", obj, {
            params: { requestID: requestID },
        });
    },

    GetKgPacking(obj, requestID) {
        return Service.post(resource + "GetKgPacking", obj, {
            params: { requestID: requestID },
        });
    },

    getReportAllCalibrated(obj, requestID) {
        return Service.post(resource + "getReportAllCalibrated", obj, {
            params: { requestID: requestID },
        });
    },

    exportExcelReport(obj, xFlag, NameReport, requestID) {
        return Service.post(resource + "exportExcelReport", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { xFlag: xFlag, NameReport: NameReport, requestID: requestID },
        });
    },


    // reporte en excel
    downloadCalibrateExcel(obj, requestID) {
        return Service.post(resource + "downloadCalibrateExcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID },
        });
    },

    // reporte en pdf
    downloadCalibratePDF(obj, requestID) {
        return Service.post(resource + "downloadCalibratePDF", {}, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { obj, requestID }
        });
    },
    // reporte en pdf
    reportCalibratePDF(obj, requestID) {
        return Service.post(resource + "reportCalibratePDF", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        });
    },

    prflotprodsupplypalletsendtransfer(requestID) {
        return Service.post(resource + "prflotprodsupplypalletsendtransfer", {}, {
            params: { requestID: requestID },
        });
    },

    prflotprodsupplypalletsendtransferwalmart(requestID) {
        return Service.post(resource + "prflotprodsupplypalletsendtransferwalmart", {}, {
            params: { requestID: requestID },
        });
    },


};