<template v-slot:no-data>
	<div>
		<v-container style="display: contents;">
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row style="margin: auto;">
							<v-col
								cols="6"
								lg="3"
								md="4"
                                align="center"
							>
								<v-card style="display: grid; background: #eaeaea;">
									<v-card-title>
										<span class="text-h6 font-weight-light"
											>Cal Mixto
										</span>
									</v-card-title>
									<v-img
										lazy-src="../../../assets/pallets44.png"
										max-height="200"
										max-width="200"
										src="../../../assets/pallets44.png"
										><v-btn
											style="border-radius: 100%; height: 44%; width: 30%; margin: auto; display: flex; justify-content: space-evenly; "
											color="warning"
											@click="openModal(col)"
										>
											<span class="white--text text-h4">{{
												40
											}}</span>
										</v-btn>
									</v-img>
								</v-card>
								<v-row style="margin-top: 0.1em;">
									<v-col cols="7">
										<s-select-definition
											:def="1181"
											style="border-right: groove;"
											v-model="TypeDestiny"
										/>
									</v-col>
									<v-col cols="5">
										<v-btn
											outlined
											small
											elevation="3"
											color="success"
											style="width:100%"
											@click="clickSend(col)"
										>
											Enviar
										</v-btn>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card>
0
				</div>
			</v-row>
		</v-container>

        <v-dialog
			v-if="openDialog"
			v-model="openDialog"
			persistent
			transition="dialog-bottom-transition"
			fullscreen
		>
			<supply-pallet :nroPallet="0" :typeCaliber="0" :typeCrop="TypeCrop" :itemPalletOne="itemPalletOne" @close="close()">
			</supply-pallet>
		</v-dialog>
	</div>
</template>
<script>
	//Service
	import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
	import _sLotProdCalibratedPrintQRService from "../../../services/FreshProduction/PrfLotProductionCalibratedQRService";


	export default {
		components: {},
		data: () => ({
            TypeDestiny: 1,
            openDialog:false
		}),

		created() {
			
		},

		watch: {
		},

		methods: {
            openModal(items) {
				
				console.log("aaaaaaa", items);
				this.itemPalletOne  = items

				this.openDialog = true;
			},

		},

	};
</script>
